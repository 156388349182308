import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import * as Styled from './segment-products.styles';
import { SegmentApiNameEnum } from '../../../models/enums/segment-api-name.enum';
import { ChildSegment } from '../../../models/segment';
import { selectCurrentSegmentCode } from '../../../redux/segments/segments-selectors';
import ChildSegmentLink from '../child-segment-link/child-segment-link';
import ChildSegmentLinkForMtsBank from '../child-segment-link/child-segment-link-for-mts-bank';

export const SegmentProducts: FC<{ childSegment: ChildSegment }> = ({ childSegment }) => {
    const segmentCode = useSelector(selectCurrentSegmentCode);

    return (
        <Styled.Wrapper>
            {childSegment.name && <Styled.Title>{childSegment.name}</Styled.Title>}
            {childSegment.code === 'finance' && segmentCode === SegmentApiNameEnum.individuals && <ChildSegmentLinkForMtsBank/>}
            {childSegment.products?.map(product => <ChildSegmentLink product={product} key={product.id} />)}
        </Styled.Wrapper>
    );
};
