import React, { useContext, useState } from 'react';
import { ThemeContext } from 'styled-components';
import * as Styled from './child-segment-link.styles';
import { useAppRouter } from '../../../custom-hooks/use-app-router';
import { makeLastSpaceNonBreaking } from '../../../helpers/make-last-space-non-breaking.helper';
import { MainPageAnalytics } from '../../../helpers/web-analytics/main-page-analytics';
// @ts-ignore
import mtsBank from '../../../images/icons/mts-bank.svg?next-images-ignore=true';
import { Icon } from '../icon/icon';
import { LoaderCircle } from '../loader-circle/loader-circle';

const ChildSegmentLinkForMtsBank: React.FC = () => {
    const router = useAppRouter();
    const [isLoading, setLoading] = useState(false);
    const themeContext = useContext(ThemeContext);

    const linkClickHandler = () => {
        setLoading(true);
        MainPageAnalytics.productNameClick('mts_bank');
        router.push('/child_segment/mts_bank');
    };

    const contentComponent = (
        <>
            <Styled.IconWrapper>
                <Icon src={mtsBank} height={44} width={44} />
            </Styled.IconWrapper>
            <Styled.LinkTextBlock>
                <Styled.LinkTitle>{makeLastSpaceNonBreaking('МТС Банк')}</Styled.LinkTitle>
                <Styled.LinkDescription>{'Карты, кредиты и ипотека'}</Styled.LinkDescription>
            </Styled.LinkTextBlock>
        </>
    );

    return (
        <Styled.Container>
            <Styled.Item
                onClick={linkClickHandler}
                isLoading={isLoading}
            >
                {contentComponent}
            </Styled.Item>
            {isLoading && <LoaderCircle type={themeContext.loaderColor} centered />}
        </Styled.Container>
    );
};

export default ChildSegmentLinkForMtsBank;
